import React from 'react';
import '../styles/styles.css';
import { Container } from 'react-bootstrap';


export const Orientation = () => (
    <Container>
        <h2 className="orientation-header">Orientation</h2>
        <button href = '#' className="orientation_button" onClick={() => window.open(process.env.PUBLIC_URL + '/orientation.pdf')}><i className="mdi mdi-help-circle">FAQ</i></button>
        <button href = '#' className="orientation_button" onClick={() => window.open(process.env.PUBLIC_URL + '/damages_repairs.pdf')}><i className="mdi mdi-help-circle">Damages & Repairs</i></button>
        <br/>
        <div className="flex-container">
        
                <div className = "box">
                <h3>When you come</h3>
                
                <b>Charlotte Douglas International Airport:</b> Is about 15 miles from
                Apartments, it takes about 20 to 30 minutes travel time. We do not provide
                Pickup/Drop service, you need to choose your own transportation.
                <br/>
                <br/>
                <b>First person to arrive in apartment:</b> You need to inform me via email,
                if you are the “First Person” your arrival date and approximate time (AM or
                PM) as on your airline ticket, in Charlotte, we need to assign someone to
                “give you the main door key”. Please keep your keys with you once you are
                outside the door, Do not lockout.
                <br/>
                <br/>
                <b>Within 7 days of arrival:</b> We expect you to settled down, you need to
                provide your “Valid Photo IDs” – i.e. Photo Page of your Passport, any
                remaining dues and August rent. I will be visiting you the following
                weekend.
                <br/>
                <br/>
                <b>Nearby:</b> There is a grocery store & restaurants across the gas station
                (cross main road “University City Blvd'' about a 10-minute walk). The other
                supplies like pans, pots, plates & glasses, table lamps, beds can be
                purchased from IKEA, Amazon or Wal-Mart either by visiting store or
                online.
                <br/>
                <br/>
                There will be no furniture or mattress in the apartment. Please Do not
                purchase used mattresses or furniture. They may come with bed bugs
                which can be clean on your (tenant’s) expense.
                <br/>
                <br/>
                <b>Keys:</b> You will have your own main door key, a room key (in your room lock
                – your room key and main door key may or may not be the same key), and
                one mailbox key for all roommates.
                <br/>
                <br/>
                <b>Internet:</b> Required User and Password.
                <br/>
                <br/>
                <b>Heating & Cooling:</b> Please keep the windows and door close to get the
                effective results, set the thermostat set to “Auto” and keep the
                temperature between 74’ to 78’F to keep utility bills low.
                <br/>
                <br/>
                <b>Cold & Hot water:</b> Water is expensive in the USA, use wisely, it will keep
                your utilities bill down. Please do not store any item on the water heater as
                it is a fire danger.
                <br/>
                </div>

                <div className= 'box'>
                <h3>Use of Appliances:</h3>
                <b>Cooking Range:</b> All the appliances are electrical, never leave unattended
                while cooking, clean after any spill food, and wipe up any grease. Keeping
                the kitchen clean will keep the bugs away.
                <br/>
                <br/>
                <b>Microwave Oven:</b> Need to be clean after use / use food cover to protect
                your food.
                <br/>
                <br/>
                <b>Refrigerator</b>: Need to clean weekly of any rotting or spilled food.
                Washing Machine: Use only laundry detergent for clothes wash and do
                not use any other soap including hand soap.
                <br/>
                <br/>
                <b>Clothes Dryer:</b> Clothes lint chamber need to be cleaned after every use,
                not cleaning the lint trap is a fire hazard. Do not dry your clothes on the
                balcony or hang out outside, there is a HOA fine of $100. Tenants will be
                responsible to pay this fine with admin fees.
                <br/>
                <br/>
                Dishwasher: Use dishwasher detergent only, this is different from
                detergent used for washing dishes by hand. Please use appropriate
                detergent. Never wash a big cooking pan and pots in dishwasher – use only
                for plates, glasses, spoons and small pots. Please clean the solid material
                from the dishes like bones, bread, chunk of food etc.
                <br/>
                <br/>
                <b>Kitchen Sink:</b> Do not drain the solid food, it may clog the drain. Avoid
                leaving the dirty dishes overnight, you will be inviting bugs and flies.
                <br/>
                <br/>
                <b>Bathroom toilets:</b> Do not throw trash, napkins, paper towels, women’s
                health products, hair, newspaper, and any solids in the toilet. Only toilet
                tissue should go in the toilet.
                <br/>
                <br/>
                <b>Bathtub:</b> Can be used for shower/bath only. Do not dispose solid, trash or
                hair in the drain, it will clog. Make sure the curtain close is inside of tub to
                avoid the water on the floor.
                <br/>
                <br/>
                <b>Bathroom Sink:</b> Same rules applies, no solid no hair please.
                <br/>
                <br/>
                <b>Bedroom:</b> Each room has window blind; shutter can be open/close for
                your privacy. Do not pull the blind all the way up, HOA privacy rules apply.
                <br/>
                </div>

                </div>
                <div className='flex-container'>
                <div className = 'box'>
                <h3>Miscellaneous</h3>
                <b>Bugs Control:</b> North Carolina is heavily wooded area; I paid and provide
                every 3 months ‘Pest Control’ service no cost to tenants. Notice will be
                sent to your mailbox, please let the Pest Control technician into the
                apartment to service the unit (kitchen, common area and bedrooms). Any
                additional service will cost you nearly $100, and will be equally divided
                within tenants (you do not need additional service, unless you miss).
                <br/>
                <br/>
                <b>Electric Switch:</b> Near the water, switch turnoff automatically during the
                power surge, unplug your appliance, press black button, then press red, it
                will turn on.
                <br/>
                <br/>
                <b>Parking Pass:</b> If you are planning to buy a car, please inform me, without
                the parking pass, your car will be towed away.
                <br/>
                <br/>
                <b>Overall cleaning:</b> Please keep the trash covered and out on a regular
                basis and clean your designated area on weekly basis, like common-area,
                bathrooms, kitchen, laundry room, stay in healthy environment. Proper
                trash bags use for trash, no trash/BBQ on the Balcony or out-side the door
                at any time, it is subject to HOA fine $100.
                <br/>
                <br/>
                <b>Maintenance:</b> When a tenant breaks or misuses an item on the property,
                the tenant is responsible for full cost of repair, service and/or replacement.
                Items are not limited to, but include kitchen, bathrooms, bedrooms, clog,
                appliances, both indoor and outdoor area.
                <br/>
                <br/>
                <b>Security:</b> Compound is a gated community, each building has a security
                camera installed, maintained and run 24/7 by HOA (Home Owners
                Association). There are not frequent security incidents in my notice. I
                strongly suggest, stay alert all time, lock your doors & windows, we do not
                provide any insurance of tenant’s belongings.
                <br/>
                </div>
                <div className='box'>
                <h3>Contact me:</h3> Any emergency, you need to understand who to call first,
                first responders like; Police, Fire Truck and/or Ambulance 911 from your US
                phone.
                <br/>
                <br/>
                You can text or email me anytime 24/7, I will reply to you as soon as
                possible. Always write your apartment number and name in text/email with
                a detailed message. It will be easy for me to understand the issue at once
                and serve you better.
                <br/>
                <br/>
                FYI: I travel for my work and live about 150 miles Northeast of Charlotte,
                texting is not allowed during driving, some places use of phone is not
                allowed while driving. Please provide me with your local number, What’s
                App is Not our regular communication method while you are in Charlotte.
                Feel free to contact me if you have any questions or concerns, there are no
                good or bad questions. 
                <br/>
                <br/>
                We wish you a happy stay.

                </div>
                            
            
        </div>
        <br/>
        
        <br/>
    </Container>
  );

