import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';

export const sidebardata = [
    {
        title: 'Home',
        path: '/',
        icon: <AiIcons.AiFillHome/>,
        cName: 'nav-text'
    },

    {
        title: 'Orientation',
        path: '/orientation',
        icon: <FaIcons.FaListAlt/>,
        cName: 'nav-text'
    },

    {
        title: 'Apartments',
        path: '/apartments',
        icon: <FaIcons.FaBuilding/>,
        cName: 'nav-text'
    },

    {
        title: "Checklist",
        path: '/forms',
        icon: <AiIcons.AiFillFilePdf/>,
        cName: 'nav-text'
    },

]