import React from 'react';

// process.env.PUBLIC_URL + '/img/logo.png'

export const Downloads = () => (

    <article className= 'downloadable_pdfs'>
      <button href = '#' className="headerProfile-menu-list" onClick={() => window.open(process.env.PUBLIC_URL + '/exit_checklist.pdf')}><i className="mdi mdi-help-circle">Exit Check List</i></button>

   </article>
 
 );

