import React from 'react';
import ut from '../assets/houses/1.jpg';
import utn from '../assets/houses/last.jpg';
import { Link } from 'react-router-dom';



var c;

var loaded = false;
fetch('available.json')
    .then(res => res.json())
    .then((data) => {      
      c = data;
      loaded = true;
    })
    .catch(console.log)


function availablerooms(){
    return(
                        
        <p class = 'availableapts'>
        <h3>Currently available</h3>
        <p>Single Rooms in University Terrace Drive:  {c.ut_single}</p> <p>Full Apartments in University Terrace Drive: {c.ut_full}</p>
        
        <p>Single Rooms in University Terrace North: {c.utn_single}</p>
        <p>Full Apartments in University Terrace North: {c.utn_full}</p>
    </p>
    );
}

export const Apartments = () => (
   
    
    <div className='grid-container'>
    <div className='item-1'>
       <p><em className= 'startword'>We</em> assist students with off-campus housing, available within the walking distance
                from University of North Carolina at Charlotte campus.
                <br/>
                <br/>
                Currently we have 4 bedrooms, 2 bathrooms housing in University Terrace (UT)
                and 4 bedrooms, with attached 4 bathrooms in University Terrace North (UTN),
                Properties have 24/7 security surveillance, Parking, and student communities.
                <br/>
                <br/>
                All bedrooms have closets, and apartments have a common living room, kitchen,
                laundry, closets, storage room and patio. All apartments have central
                heating/cooling systems, hot &amp; cold water, and internet. Kitchens are equipped
                with basic appliances (ex. stove, refrigerator, microwave, dishwasher),
                washer/dryer is located inside the apartment in laundry room.
                <br/>
                <br/>
                These apartments are available for students only on first come first serve basis.
                We do not hold properties unless a biliteral lease has been fully signed and
                security deposit paid.
                <br/>
                <br/>
                

                {loaded?  availablerooms() : <div/>}
                
              </p>
    </div>

    <div className = 'item-2'>
        <h4> Fill your information on<Link to='/'><span>Home Page</span></Link> for details.</h4>
         <div className='photo-apt'>

        < div key={1} className='utn'>
                            <h4>                        
                                University Terrace North
                            </h4>
                            <img src = {utn} alt= ''/>
                        </div>     

                       
                        <div key={2} className='ut'>
                            <h4>                        
                                University Terrace          
                            </h4>
                            <img src = {ut} alt= ''/>
                        </div>  

              </div>   
        </div>
    </div> 

  );

