import React from 'react';
import {Container} from 'react-bootstrap';



// import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import {Jumbotron} from './jumbotron';
import Navbar from './navbar';




export const Layout=(props) => (
    <Container>
        <header>        
          <Navbar/>
          <Jumbotron/>
        </header>

        <main>
        {props.children}
        </main>

        
        <footer>Asap Consultants LLC</footer>
    </Container>
)