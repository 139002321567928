import React from 'react';


export const ErrorPage = () => (
      <div>
          This is the error page<br/>
          look it works
      </div>

  );

