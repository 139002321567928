import React from 'react';
import { Jumbotron as Jumbo } from 'react-bootstrap';
import styled from 'styled-components';
import ai from '../assets/1.PNG'


// 

const Styles = styled.div`
  .jumbo {
    background: url(${ai});
    color: #efefef;
    position: relative;
    text-align: right;
    background-repeat: no-repeat;
    background-size: 100% 100%;
      
  
  }
  .overlay {
    background-color: #000;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;

  }

  .tagline {
    padding: 10%;
  }
`;

export const Jumbotron = () => (
  <Styles>
    <Jumbo fluid className="jumbo">
      <div className="overlay"></div>
    </Jumbo>
  </Styles>
)