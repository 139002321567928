import React from 'react';
import "../styles/styles.css";
import Form from '../components/Form';
import Hcarousel from '../components/carousel';




export const Home = () => (
  <div className='grid-container'>
    <div className='item-1'>
      <p><em className= 'startword'>We</em> assist students with off-campus housing, available within the walking distance
                from University of North Carolina at Charlotte campus.
                <br />
        <br />
                Currently we have 4 bedrooms, 2 bathrooms housing in University Terrace (UT)
                and 4 bedrooms, with attached 4 bathrooms in University Terrace North (UTN),
                Properties have 24/7 security surveillance, Parking, and student communities.
                <br />
        <br />
                All bedrooms have closets, and apartments have a common living room, kitchen,
                laundry, closets, storage room and patio. All apartments have central
                heating/cooling systems, hot &amp; cold water, and internet. Kitchens are equipped
                with basic appliances (ex. stove, refrigerator, microwave, dishwasher),
                washer/dryer is located inside the apartment in laundry room.
                <br />
        <br />
                These apartments are available for students only on first come first serve basis.
                We do not hold properties unless a biliteral lease has been fully signed and
                security deposit paid.
              </p>
    <div className='carousel'>
    <Hcarousel />
    </div>
    </div>
    <div className='item-2'>
    <Form />
    </div>
  </div>

);

