// Import Statements
import React from 'react';
import emailjs from 'emailjs-com';
import Recaptcha from 'react-recaptcha';


// verified variable to ensure that captcha is used
var isVerified = false;

// verifying input and captcha
function verifyQuery(){
      if (isVerified) {
            return true;
      }
      else {
            return false;
      }
}

function RecaptchaLoaded (){
      console.log("Captcha is loaded")
}

function verifyClick(){
  isVerified = true
}



// Forms
export default function Form() {

  // Sends email after triggered
  function sendEmail(e) {
    e.preventDefault();
    

    if (isVerified===true )   {
      emailjs.sendForm('service_9orw8qp', 'template_6rbt5uf', e.target, 'user_CirmGLglgD5Dfvn6oQleh')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        }
      );
    }
  
      e.target.reset();
      // grecaptcha.reset();
  }

  // forms
  return (
    <div className='form-container'><h5>Please fill your information below for details and leasing.</h5>
    <form className="form" onSubmit={sendEmail}> 
      <label>Name</label>
      <input type="text" name="name" placeholder= 'John Doe'/>
      <label for="phone">Phone</label>
      <input type="text" id="phone" name="phone" pattern="[0-9]{10}" placeholder='9876543210'></input>
      <label>Email</label>
      <input type="email" name="email" placeholder= 'jdoe@gmail.com'/>
      <label>Question</label>
      <textarea name="message" placeholder= 'limit to 500 characters'/>
      <br/>
      <label className>Interested In</label>
      <div className='checkboxdiv'>
      <input type="checkbox" id="UT" name="UT" value="UT"/>
      <label for="UT"> UT (University Terrace Drive)</label>
      <span class="checkmark"></span>
      </div>
      <div className='checkboxdiv'>
      <input type="checkbox" id="UTN" name="UTN" value="UTN"/>
      <label for="UTN"> UT North (University Terrace North)</label>
      <span class="checkmark"></span>
      </div>
      <Recaptcha
                  sitekey="6Ld9R8MZAAAAANLOByUP3PSdHvldPIgvqDiFMBZO"
                  render="explicit"
                  onloadCallback={RecaptchaLoaded}
                  verifyCallback={verifyClick}
                  
      />
      <br/>
      <input type="submit" value="Send" onClick = {verifyQuery}/>
    </form>
    </div>
  );
}

