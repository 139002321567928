
import React from 'react';
import Carousel from "react-elastic-carousel";
import "../styles/styles.css";
import h1 from '../assets/houses/1.jpg'
import h2 from '../assets/houses/2.jpg'
import h3 from '../assets/houses/3.jpg'
import h4 from '../assets/houses/4.jpg'
import h5 from '../assets/houses/5.jpg'
import h6 from '../assets/houses/6.jpg'
import h7 from '../assets/houses/7.jpg'
import h8 from '../assets/houses/8.jpg'
import h9 from '../assets/houses/last.jpg'

const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 },
  ];

  var items = [
    
    {id: 1, url: h1},
    {id: 2, url: h2},
    {id: 3, url: h3},
    {id: 4, url: h4},
    {id: 5, url: h5},
    {id: 6, url: h6},
    {id: 7, url: h7},
    {id: 8, url: h8},
    {id: 9, url: h9},


  ]

  export default function Hcarousel() {
      return(
        <aside className= 'housepictures'>
          <Carousel breakPoints={breakPoints} className = "houseCarousel">
            
            {items.map(item=><img key = {item.id} src = {item.url} alt = 'house' className = 'pictures'/>)}
            
          </Carousel>
        </aside>
      )
  }