import React, { Component } from 'react';
import './App.css';

import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import {Home} from './pages/Home';
import {Apartments} from './pages/Apartments';
import {Orientation} from './pages/Orientation';
import {ErrorPage} from './pages/ErrorPage';
import {Downloads} from './pages/Downloads';
import {Layout} from './components/layout';


class App extends Component {
    
  render(){
  return (
      <React.Fragment>        
        
          <Router>
              <Layout>
                <Switch>                  
                  <Route exact path="/" component= {Home}/>
                  <Route path="/apartments" component= {Apartments}/>
                  <Route path="/orientation" component= {Orientation}/>
                  <Route path="/forms" component= {Downloads}/>
                  <Route component= {ErrorPage}/>    
                              
                </Switch>
              </Layout>    
          </Router>
          

      </React.Fragment>
  );
}
}

export default App;
